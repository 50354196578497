import '../styles/globals.css';
import '../styles/global.scss';
import { ApolloProvider } from '@apollo/client';
import type { AppProps } from 'next/app';
import getClient from '../graphql/client';
import { createTheme, ThemeProvider, responsiveFontSizes } from '@mui/material';

export default function App({ Component, pageProps }: AppProps) {
  const theme = responsiveFontSizes(
    createTheme({
      typography: {
        allVariants: {
          fontFamily: 'Open Sans',
        },
      },
    })
  );

  return (
    <ApolloProvider client={getClient()}>
      <ThemeProvider theme={theme}>
        <Component {...pageProps} />
      </ThemeProvider>
    </ApolloProvider>
  );
}
